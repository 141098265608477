import React from 'react';
import ReactDOM from 'react-dom/client';
import "./index.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Auth from './layouts/Auth';
import Admin from './layouts/Admin';
import { Toaster } from 'sonner';
import OrderLists from './layouts/OrderLists';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import OrderTrackingInfo from './layouts/OrderTrackingInfo';
import BankDetails from './layouts/BankDetails';
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
  <>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename='/stellar/app'>
        <Routes>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="/order-lists" element={<OrderLists />} />
          <Route path="/orders/tracking/:orderNumber?" element={<OrderTrackingInfo />} />
          <Route path="/orders/bank-details" element={<BankDetails />} />
          <Route path="/" element={<Navigate to="/auth/login" />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
    <Toaster position="top-right" duration={3000} richColors toastOptions={{
      style: { background: '#eae6f3', boxShadow: "1px 1px 8px 8px rgba(115, 92, 173, 0.12)", border: "1px solid rgb(115, 92, 173)" },
    }} />
  </>
);
reportWebVitals();
