import React, { useState } from "react";
// import UserDropdown from "../Dropdowns/UserDropdown";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal"; 

export default function Navbar() {
  const navigate = useNavigate();
  const [confirmLogOut, setConfirmLogOut] = useState(false);

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {/* Brand */}
          <a
            className="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Dashboard
          </a>
          {/* Form */}
          {/* <form className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
            <div className="relative flex w-full flex-wrap items-stretch">
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                <i className="fas fa-search"></i>
              </span>
              <input
                type="text"
                placeholder="Search here..."
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
              />
            </div>
          </form> */}
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            {/* <UserDropdown /> */}
            <li className="items-center">
                <div
                onClick={()=>setConfirmLogOut(true)}
                  className={
                    "text-xs uppercase py-3 font-bold block cursor-pointer text-blueGray-700 hover:text-lightBlue-600"
                  }
                >
                  <i
                    className={
                      "fas fa-sign-out mr-2 text-lg " +
                      (window.location.href.indexOf("/admin/maps") !== -1
                        ? "opacity-75"
                        : "text-red-600")
                    }
                  ></i>
                </div>
            </li>
          </ul>
        </div>
      </nav>
      <Modal
        className="modal-menu m-auto"
        isOpen={confirmLogOut}
        onRequestClose={() => setConfirmLogOut(false)}
        contentLabel="XYZ Component Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        }}
      >
        <div className="p-[50px]">
          <h1 className="text-center my-4 text-lg font-bold">Are You Sure want to Log Out?</h1>
          <div className="flex mt-2 mb-1 px-14 gap-10">
            <button className="px-5 py-2 rounded-md text-white bg-red-500 hover:bg-red-600" onClick={()=>{localStorage.removeItem("token"); localStorage.removeItem("role"); navigate("/auth/login")}}>Yes</button>
            <button className="px-5 py-2 rounded-md text-white bg-green-500 hover:bg-green-600" onClick={()=>setConfirmLogOut(false)}>No</button>
          </div>
        </div>
      </Modal>
      {/* End Navbar */}
    </>
  );
}
