import axios from "axios";

export const baseUrl = "https://dabur.techtonicretail.com/stellar/api/"

// export const baseUrl = "http://localhost:3000/"
export const queryApi = axios.create({
  baseURL: 'https://stage.techtonicretail.com/api/', // Replace with your API URL
});

export const regularExpression = {
    EMAIL_REGEX: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    MOBILE_REGEX: /^[6-9]\d{9}$/,
    NAME_WITH_CHARACTERS_REGEX: /^[A-Za-z0-9&\s.]+$/,
    COMPANY_NAME_REGEX: /^[A-Za-z0-9&\s.]+$/,
    ADDRESS_REGEX: /^[a-zA-Z0-9\s.,-/]+$/,
    PINCODE_REGEX: /^[1-9][0-9]{5}$/,
    AMOUNTS_REGEX: /^\d+$/,
    PAN_REGEX: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
    IFSC_REGEX: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    UPIID_REGEX: /[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/,
    ACCOUNTNUMBER_REGEX: /^[0-9]{10,16}$/,
    GST_REGEX: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    CIN_NO_REGEX: /^[A-Z][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
    COMPANY_WEBSITE_REGEX: /^(https?:\/\/)?(www\.)?([A-Za-z0-9.-]+\.[A-Za-z]{2,})(\/[A-Za-z0-9-._~:/?#[\]@!$&'()*+,;:]*)?$/,
    ALPHA_NUMERIC_REGEX: /^[A-Za-z0-9 ]*$/,
    NUMERIC_DECIMAL_REGEX: /^\d+(\.\d+)?$/,
    DRIVER_LICENSE: /^[A-Za-z0-9]{6,10}$/,
    FLOORS_REGEX: /^[0-9a-zA-Z- ]+$/,
    INSURANCE_PERCENTAGE_REGEX: /^[0-9]+(\.[0-9]+)?$/
}

export const dateTimeFormatter = (userdate) =>{

    if(!userdate) return "N.A."
    const date = new Date(userdate);

    // Format the date
    const options = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
  
    return date.toLocaleString('en-US', options);
}