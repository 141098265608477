import React, { useState } from 'react';

const BankDetails = () => {
  const [formValues, setFormValues] = useState({
    accountHolderName: '',
    ifscCode: '',
    accountNumber: '',
    branchName: '',
    address: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted:', formValues);
  };

  return (
    <>
    
    <div className="flex items-center justify-center min-h-screen bg-gray-100 mt-[1 0px]">
      <form
        className="w-full max-w-md bg-white p-6 rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        <h2 className="text-2xl font-bold mb-4 text-center">Bank Details</h2>
        
        <div className="mb-4">
          <label
            htmlFor="accountHolderName"
            className="block text-sm font-medium text-gray-700"
          >
            Account Holder Name
          </label>
          <input
            type="text"
            id="accountHolderName"
            name="accountHolderName"
            value={formValues.accountHolderName}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#02500999] focus:border-[#02500999]"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="ifscCode"
            className="block text-sm font-medium text-gray-700"
          >
            IFSC Code
          </label>
          <input
            type="text"
            id="ifscCode"
            name="ifscCode"
            value={formValues.ifscCode}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#02500999] focus:border-[#02500999]"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="accountNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Account Number
          </label>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            value={formValues.accountNumber}
            onChange={handleChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#02500999] focus:border-[#02500999]"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="branchName"
            className="block text-sm font-medium text-gray-700"
          >
            Branch Name
          </label>
          <input
            type="text"
            id="branchName"
            name="branchName"
            value={formValues.branchName}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#02500999] focus:border-[#02500999]"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700"
          >
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formValues.address}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#02500999] focus:border-[#02500999]"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-[#02500999] text-white py-2 px-4 rounded-md hover:bg-[#02500999] focus:outline-none focus:ring-2 focus:ring-[#02500999] focus:ring-offset-2"
        >
          Submit
        </button>
      </form>
    </div>
    </>
  );
};

export default BankDetails;
